.Header #logo {
  opacity: 0;
  transition: opacity .5s linear; }

.Header .navbar.navbar-default {
  background-color: #fd3b37;
  border: none;
  border-radius: 0px;
  margin-bottom: 0px; }

.Header .navbar-default .navbar-nav > li > a {
  color: white;
  padding-bottom: calc(15px - 3px);
  border-bottom: solid 3px transparent;
  font-size: 18px; }

.Header .navbar-default .navbar-nav > li > a:hover {
  border-color: #3f4141; }

.Header .navbar-default .navbar-nav > .open > a, .Header .navbar-default .navbar-nav > .open > a:focus, .Header .navbar-default .navbar-nav > .open > a:hover {
  background-color: #3f4141; }

.Header .navbar-default .navbar-brand img {
  height: 20px; }

.Header ul.dropdown-menu {
  font-size: 18px;
  background-color: #fd3b37;
  border-color: #3f4141; }

.Header ul.dropdown-menu a {
  color: white; }

.Header ul.dropdown-menu a:hover, .Header ul.dropdown-menu a:focus {
  color: #555;
  background-color: #3f4141; }

@media (max-width: 767px) {
  .Header .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: white; } }
