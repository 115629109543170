html {
  font-size: 16px; }

@media (min-width: 768px) {
  html {
    font-size: 18px; } }

.card-deck .card {
  min-width: 220px; }

section {
  padding: 70px 0;
  font-size: 18px;
  color: #3f4141;
  background-color: white;
  background: linear-gradient(to top right, white, #f3f3f3); }

section.primary {
  color: white;
  background-color: #fd3b37;
  background: linear-gradient(to bottom right, #fd3b37, #fe3682); }

section.dark {
  color: white;
  background: #3f4141; }

section.dark a {
  color: #fd3b37; }

section.primary a {
  color: #3f4141; }

section h1 {
  font-weight: 300; }
